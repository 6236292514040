<template>
    <section class="page-giftcards-items-form">
        <transition name="fade">
            <div class="page-giftcards-items-form__loader"
                v-if="isLoading"
                >
                <div class="page-giftcards-items-form__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-giftcards-items-form__options">
            <div class="page-giftcards-items-form__options-item" v-bind:style="{ backgroundImage: certificateTypeData.background }">
                <div class="page-giftcards-items-form__options-item-placeholder"></div>
                <div class="page-giftcards-items-form__options-item-description">
                    {{ certificateTypeData.description }}
                </div>
            </div>
            <div class="page-giftcards-items-form__options-item">
                <div class="page-giftcards-items-form__content">
                    <div class="page-giftcards-items-form__content-form">
                        <ui-form
                            v-bind:model="fields"
                            v-bind:validation="$v"
                            v-on:update="updateHandler"
                            v-bind:submit-handler="submitHandler"
                        />
                    </div>
                    <div class="page-giftcards-items-form__content-button">
                        <ui-button
                            v-on:click="submitHandler"
                            v-bind:class="{ _disabled: isLoading }"
                            v-bind:disabled="isLoading"
                            is-uppercase
                            type="submit"
                        >
                            добавить в корзину
                        </ui-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import config from '~/config';
import forms from '~/forms';
import { productDetailsView } from '~/utils/ecommerce';
import utils from '~/utils';

export default {
    name: 'page-giftcards-items-form',
    inject: [ 'type', 'requestUrl', 'staticPathRoot' ],
    props: [ 'selectedItem' ],
    data() {
        return {
            isSubmitted: false,
            fieldsData: {
                giftCardRecipientName: {
                    ...forms.fields.giftCardRecipientName,
                    label: this.type === 'digital' ? forms.fields.giftCardRecipientName.label : `${forms.fields.giftCardRecipientName.label} (по желанию)`,
                    isHalf: window.innerWidth >= 568,
                    placeholder: this.type === 'digital' ? '' : 'до 15 символов',
                },
                giftCardSenderName: {
                    ...forms.fields.giftCardSenderName,
                    label: this.type === 'digital' ? forms.fields.giftCardSenderName.label : `${forms.fields.giftCardSenderName.label} (по желанию)`,
                    isHalf: window.innerWidth >= 568,
                    placeholder: this.type === 'digital' ? '' : 'до 15 символов',
                },
                giftCardAmount: { ...forms.fields.giftCardAmount },
                giftCardSenderEmail: { ...forms.fields.giftCardSenderEmail },
                giftCardRecipientEmail: { ...forms.fields.giftCardRecipientEmail },
            },
            isDesktop: window.innerWidth >= 1024,
        };
    },
    validations() {
        const result = {
            fields: {
                giftCardRecipientName: {
                    value: {
                        ...forms.validations.maxLength(forms.fields.giftCardRecipientName.maxLength).value,
                    },
                },
                giftCardSenderName: {
                    value: {
                        ...forms.validations.maxLength(forms.fields.giftCardSenderName.maxLength).value,
                    },
                },
            },
        };
        if (this.type === 'digital') {
            result.fields.giftCardSenderEmail = {
                value: {
                    ...forms.validations.defaultRequired.value,
                    ...forms.validations.email.value,
                },
            };
            result.fields.giftCardRecipientName = {
                value: {
                    ...forms.validations.defaultRequired.value,
                    ...forms.validations.maxLength(forms.fields.giftCardRecipientName.maxLength).value,
                },
            };
            result.fields.giftCardSenderName = {
                value: {
                    ...forms.validations.defaultRequired.value,
                    ...forms.validations.maxLength(forms.fields.giftCardSenderName.maxLength).value,
                },
            };
            if (this.fieldsData.giftCardRecipientEmail.value) {
                result.fields.giftCardRecipientEmail = {
                    value: {
                        ...forms.validations.email.value,
                        ...forms.validations.defaultRequired.value,
                    },
                };
            }
        }
        if (this.selectedItem.isCustom) {
            result.fields.giftCardAmount = {
                value: {
                    ...forms.validations.defaultRequired.value,
                    invalidAmount: value => parseInt(value, 10) >= config.customGiftcardsLimits[this.type].min && parseInt(value, 10) <= config.customGiftcardsLimits[this.type].max,
                },
            };
        }
        return result;
    },
    computed: {
        isLoading() {
            return this.$store.getters['cart/isLoading'];
        },
        result() {
            return !this.isLoading && this.isSubmitted && this.$store.state.cart.getCartResult && !this.$store.state.cart.addToCartError;
        },
        fields: {
            get() {
                const result = {
                    giftCardRecipientName: this.fieldsData.giftCardRecipientName,
                    giftCardSenderName: this.fieldsData.giftCardSenderName,
                };
                if (this.selectedItem.isCustom) {
                    result.giftCardAmount = this.fieldsData.giftCardAmount;
                    const minFormatted = config.customGiftcardsLimits[this.type].min.toLocaleString('ru-RU');
                    const maxFormatted = config.customGiftcardsLimits[this.type].max.toLocaleString('ru-RU');
                    result.giftCardAmount.labelHint = `От ${minFormatted} до ${maxFormatted} ₽`;
                    result.giftCardAmount.errors = {
                        required: `Введите сумму от ${minFormatted} до ${maxFormatted} ₽`,
                        invalidAmount: `Введите сумму от ${minFormatted} до ${maxFormatted} ₽`,
                    };
                }
                if (this.type === 'digital') {
                    result.giftCardSenderEmail = this.fieldsData.giftCardSenderEmail;
                    result.giftCardRecipientEmail = this.fieldsData.giftCardRecipientEmail;
                }
                return result;
            },
            set(newData) {
                this.fieldsData = { ...this.fieldsData, ...newData };
            },
        },
        certificateTypeData() {
            const resize = utils.common.getResize({ width: this.isDesktop ? 50 : 100 });
            if (this.type === 'digital') {
                return {
                    description: 'Сертификат будет отправлен вам на почту, а также доступен для скачивания в форматах PDF и JPG',
                    background: `url(${this.staticPathRoot + utils.common.injectResize('/images/gift-cards/background-digital-desktop-v4.png', resize)})`,
                    // background: `url(https://cdn.pyeoptics.com/staging_static_files/images/gift-cards/1280xAUTO/background-digital-desktop-v4.png)`,
                };
            }
            return {
                description: 'Плотная дизайнерская бумага, тиснёный посеребрённый шрифт, объёмный принт на конверте. Открытка внутри — заполним по вашему желанию',
                background: `url(${this.staticPathRoot + utils.common.injectResize('/images/gift-cards/background-paper-desktop-v4.png', resize)})`,
                // background: `url(https://cdn.pyeoptics.com/staging_static_files/images/gift-cards/1280xAUTO/background-paper-desktop-v4.png)`,
            };
        },
    },
    methods: {
        updateHandler(newData) {
            this.fields = {
                ...this.fields,
                ...newData,
            };
        },
        submitHandler() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let description = {
                    name_to: this.fieldsData.giftCardRecipientName.value,
                    name_from: this.fieldsData.giftCardSenderName.value,
                };
                if (this.type === 'digital') {
                    description.email_from = this.fieldsData.giftCardSenderEmail.value;
                    if (this.fieldsData.giftCardRecipientEmail.value) {
                        description.email_to = this.fieldsData.giftCardRecipientEmail.value;
                    }
                }
                const params = {
                    quantity: this.selectedItem.isCustom ? this.fieldsData.giftCardAmount.value : 1,
                    description,
                    product: this.selectedItem,
                };
                if (params.product?.ecommerce) {
                    params.product.ecommerce.listId = 'catalog_gift_cards';
                    params.product.ecommerce.listTitle = 'Подарочные сертификаты';
                }
                this.isSubmitted = true;
                this.$store.dispatch('cart/addToCart', params);
            }
        },
        onResize() {
            this.isDesktop = window.innerWidth >= 1024;
        },
    },
    mounted() {
        this.selectedItem.ecommerce && productDetailsView({
            ...this.selectedItem.ecommerce,
            listId: 'catalog_gift_cards',
            listTitle: 'Подарочные сертификаты',
        });
    },
    beforeMount() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    watch: {
        result(newVal) {
            if (newVal !== null) {
                this.$store.commit('modals/push', {
                    name: 'common',
                    props: {
                        title: 'Успешно!',
                        text: 'Сертификат добавлен в корзину!',
                        buttons: [
                            { text: 'Перейти в корзину', callback: () => { window.location.href = config.urls.cart; } },
                        ],
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-giftcards-items-form {
    position: relative;

    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    //padding: 50px 40px 60px;

    background: @color-gray-main;

    &__options {
        flex: 0 0 auto;
        display: flex;
        width: 100%;
        border-top: 1px solid @color-gray-lightest;

        &-item {
            position: relative;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            //padding: 40px 20px 80px;
            padding: 64px 110px;
            //aspect-ratio: 3088 / 2500;
            aspect-ratio: 1920 / 1554;

            color: @color-gray-darkest;
            text-decoration: none;

            background-color: @color-gray-main;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            &:nth-child(2n) {
                border-left: 1px solid @color-gray-lightest;
            }
            &-description {
                .typography-body-md();
                position: absolute;
                bottom: 10%;
                max-width: 480px;
                text-align: center;
            }
            @media @media-lg-only {
                &-description {
                    bottom: 8%;
                }
            }
            @media @media-sm-down {
                &-description {
                    bottom: 5%;
                    padding: 0 10px;
                }
            }

            &-placeholder {
                width: 200px;
                aspect-ratio: 250/500;
                margin: auto;
                pointer-events: none;
            }
            &-title {
                .typography-display-md();

                margin-bottom: 8px;
            }
            &-subtitle {
                margin-top: 0;
                margin-bottom: 40px;
            }
            &-placeholder {
                width: 193px;
                aspect-ratio: 193/272;
                margin: auto;
                // opacity: 0;
                pointer-events: none;
            }
        }
    }
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: @color-gray-main;
        &-container {
            width: 100px;
        }
    }
    &__content {
        max-width: 500px;
        width: 100%;
        &-form {
            margin-bottom: 48px;
        }
    }

    @media @media-md-down {
        &__options {
            flex-direction: column;

            &-item {
                width: 100%;
                padding: 24px 16px 74px 24px;

                &:nth-child(2n) {
                    border-left: none;
                    border-top: 1px solid @color-gray-lightest;
                }
            }
        }
    }
    @media @media-sm-down {
        &__options {
            &-item {
                background-size: 100%;
                background-repeat: no-repeat;
            }
        }
    }
}
</style>
