export const name = {
    name: 'name',
    label: 'Имя и фамилия',
    type: 'text',
    autocomplete: 'name',
    placeholder: 'Имя и фамилия',
    value: '',
    errors: {
        required: 'Введите имя и фамилию',
        twoWords: 'Введите имя и фамилию',
    },
};

export const firstName = {
    name: 'firstName',
    label: 'Ваше имя',
    type: 'text',
    autocomplete: 'given-name',
    placeholder: 'Имя',
    value: '',
    errors: {
        required: 'Введите имя',
    },
};

export const lastName = {
    name: 'lastName',
    label: 'Ваша фамилия',
    type: 'text',
    autocomplete: 'family-name',
    placeholder: 'Фамилия',
    value: '',
    errors: {
        required: 'Введите фамилию',
    },
};

export const email = {
    name: 'email',
    label: 'E-mail',
    type: 'email',
    autocomplete: 'email',
    // regex: RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
    // placeholder: 'Адрес электронной почты',
    value: '',
    errors: {
        emailv: 'Введите корректный email',
        required: 'Введите корректный email',
    },
};

export const phone = {
    name: 'phone',
    label: 'Мобильный телефон',
    type: 'phone',
    autocomplete: 'tel',
    strictPhoneCheck: false,
    placeholder: '+7',
    value: '',
    errors: {
        required: 'Введите корректный номер',
        minLength: 'Введите корректный номер',
        isValidStrict: 'Введите номер в формате +70000000000',
        isValidForBackend: 'Введите корректный номер',
    },
};

export const password = {
    name: 'password',
    label: 'Пароль',
    type: 'password',
    // placeholder: 'Введите пароль',
    value: '',
    errors: {
        required: 'Введите пароль',
    },
};

export const passwordNew = {
    ...password,
    errors: {
        required: 'Введите новый пароль',
    },
    autocomplete: 'new-password',
};

export const passwordNew2 = {
    ...password,
    label: 'Повторите пароль',
    name: 'passwordRepeat',
    errors: {
        required: 'Введите пароль снова',
        sameAsPassword: 'Введенные пароли не совпадают',
    },
    autocomplete: 'new-password',
};

export const passwordCurrent = {
    ...password,
    autocomplete: 'current-password',
    hint: 'recovery',
};

export const policy = {
    name: 'policy',
    label: 'Согласен(-на) с Политикой конфиденциальности',
    type: 'checkbox',
    value: false,
    errors: {
        checked: 'Необходимо принять политику конфиденциальности',
    },
};

export const newsletterAgreement = {
    name: 'newsletterAgreement',
    label: 'Подписаться на рассылку',
    type: 'checkbox',
    value: false,
    errors: {
        required: 'Введите имя',
    },
};

export const vouchercode = {
    name: 'vouchercode',
    label: 'Промокод',
    value: '',
    type: 'text',
    placeholder: '',
    errors: {
        required: 'Введите код',
    },
};

export const certificate = {
    name: 'vouchercode',
    label: 'Номер сертификата',
    value: '',
    type: 'text',
    placeholder: '',
    errors: {
        required: 'Введите код',
    },
};

export const country = {
    name: 'country',
    label: 'Страна',
    value: '',
    type: 'select',
    placeholder: 'Выберите страну',
    errors: {
        required: 'Выберите страну',
    },
};

export const customCountry = {
    name: 'custom-country',
    label: 'Название страны',
    autocomplete: 'country-name',
    value: '',
    type: 'text',
    placeholder: '',
    errors: {
        required: 'Укажите страну',
    },
};

export const city = {
    name: 'city',
    label: 'Город',
    value: '',
    type: 'select',
    placeholder: 'Выберите город',
    errors: {
        required: 'Укажите город',
    },
};

export const customCity = {
    name: 'custom-city',
    label: 'Название города',
    autocomplete: 'address-level2',
    value: '',
    type: 'text',
    placeholder: '',
    errors: {
        required: 'Укажите город',
    },
};

export const customDadataCity = {
    ...customCity,
    name: 'custom-dadata-city',
    type: 'dadata',
    dadataTransform: function(dadataResult) {
        return dadataResult.reduce((acc, curr) => {
            const city = curr.data.city;
            const excludedCities = ['Москва', 'Санкт-Петербург'];
            if (city && excludedCities.indexOf(city) < 0) {
                const index = acc.findIndex(x => x.value === city);
                const itemToPush = {
                    value: city,
                    postcode: curr.data.postal_code,
                    suggestTitle: city,
                    suggestRegion: curr.data.region_with_type,
                    fiasId: curr.data.fias_id,
                    showRegion: false,
                };
                if (index > -1) {
                    acc[index].showRegion = true;
                    itemToPush.showRegion = true;
                }
                if (!acc.find(x => x.value === city && x.suggestRegion === curr.data.region_with_type)) {
                    acc.push(itemToPush);
                }
            }
            return acc;
        }, []).sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            return 0;
        });
    },
    // dadataInputParser: rawValue => rawValue.showRegion ? rawValue : rawValue.value,
    errors: {
        required: 'Введите название города и выберите подходящий вариант',
    },
};

export const street = {
    name: 'street',
    autocomplete: 'street-address',
    label: 'Улица, дом, квартира/офис',
    value: '',
    type: 'text',
    placeholder: '',
    errors: {
        required: 'Укажите улицу, дом, квартиру или офис',
    },
};

export const streetDadata = {
    name: 'street-dadata',
    label: 'Улица и дом или полный адрес',
    autocomplete: 'address-street',
    value: '',
    type: 'dadata',
    dadataTransform: function(dadataResult) {
        return dadataResult.map(x => ({
            ...x,
            suggestTitle: x.value,
        }));
    },
    placeholder: '',
    errors: {
        required: 'Укажите адрес и выберите подходящий вариант',
        isHouseValid: 'Укажите номер дома',
    },
};

export const house = {
    name: 'house',
    autocomplete: 'house',
    label: 'Номер дома',
    value: '',
    type: 'text',
    placeholder: '',
    errors: {
        required: 'Укажите номер дома',
    },
};

export const postcode = {
    name: 'postcode',
    autocomplete: 'postcode',
    label: 'Почтовый индекс',
    value: '',
    type: 'text',
    placeholder: '',
    errors: {
        required: 'Не удалось рассчитать индекс, укажите вручную',
    },
};

export const building = {
    name: 'building',
    autocomplete: 'building',
    label: 'Корпус',
    value: '',
    type: 'text',
    placeholder: '',
    errors: {
        required: 'Укажите корпус',
    },
};

export const apartment = {
    name: 'apartment',
    autocomplete: 'apartment',
    label: 'Квартира/офис',
    value: '',
    type: 'text',
    placeholder: '',
    errors: {
        required: 'Укажите номер квартиры/офиса',
    },
};

export const commentCourier = {
    name: 'comment-courier',
    autocomplete: 'comment-courier',
    label: 'Комментарий для курьера',
    value: '',
    type: 'textarea',
    isExpandable: true,
    placeholder: '',
    maxLength: 1000,
    errors: {
        maxLength: 'Превышен лимит символов (1000)',
    },
};

export const commentOrder = {
    name: 'comment-order',
    autocomplete: 'comment-order',
    label: 'Комментарий к заказу',
    value: '',
    type: 'textarea',
    isExpandable: true,
    placeholder: '',
    maxLength: 1000,
    errors: {
        maxLength: 'Превышен лимит символов (1000)',
    },
};

export const communication = {
    name: 'communication',
    label: `Как с вами связаться при необходимости?`,
    value: '',
    type: 'select',
    placeholder: 'Выберите вариант',
    errors: {
        required: 'Выберите способ связи',
    },
};

// giftcards fields
export const giftCardRecipientName = {
    name: 'giftCardRecipientName',
    label: 'Кому',
    value: '',
    type: 'text',
    placeholder: 'Капибаре',
    maxLength: 15,
    errors: {
        required: 'Укажите получателя',
        maxLength: 'Превышен лимит символов (15)',
    },
};

export const giftCardRecipientEmail = {
    name: 'giftCardRecipientEmail',
    label: 'E-mail получателя (по желанию).',
    labelHint: 'Отправим копию письма',
    value: '',
    type: 'email',
    placeholder: '',
    errors: {
        emailv: 'Введите корректный email',
        required: 'Введите корректный email',
    },
};

export const giftCardSenderName = {
    name: 'giftCardSenderName',
    label: 'От кого',
    value: '',
    type: 'text',
    placeholder: 'Капибары',
    maxLength: 15,
    errors: {
        required: 'Укажите отправителя',
        maxLength: 'Превышен лимит символов (15)',
    },
};

export const giftCardSenderEmail = {
    name: 'giftCardSenderEmail',
    label: 'Ваш e-mail.',
    labelHint: 'Отправим письмо с сертификатом',
    value: '',
    type: 'email',
    placeholder: '',
    errors: {
        emailv: 'Введите корректный email',
        required: 'Введите корректный email',
    },
};

export const giftCardAmount = {
    name: 'giftCardAmount',
    label: 'Номинал, ₽',
    value: '',
    type: 'numeric',
    placeholder: '',
    errors: {
        required: 'Введите номинал',
    },
};

export const smsVerificationCode = {
    name: 'smsVerificationCode',
    label: '',
    value: '',
    type: 'numeric',
    autocomplete: 'one-time-code',
    placeholder: '',
    errors: {
        required: 'Введите код',
    },
};
